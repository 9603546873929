import React from "react"
import { Spinner } from 'react-bootstrap'
export default class Moonk extends React.Component {

    componentDidMount() {
        window.location.replace("https://moonk.financiae.cl/");
    }

    render() {
        return (
            <div className="text-center">
                <Spinner animation="border" variant="info" className="loadred" />
            </div>
        )
    }
}
